function Invoices () {
  if (window.location.pathname.includes('invoices')) {
    $('#datetimepicker1')
      .datepicker({ format: 'yyyy-mm-dd' })
      .on('changeDate', function (selected) {
        const minDate = new Date(selected.date.valueOf())
        $('#datetimepicker1').datepicker({ dateFormat: 'yyyy-mm-dd' })
        $('#datetimepicker2').datepicker('setStartDate', minDate)
      })

    $('#datetimepicker2').datepicker({ format: 'yyyy-mm-dd' })
  }
}

export default Invoices
